import { graphql } from 'gatsby';
import React, { useMemo } from 'react';
import Helmet from 'react-helmet';
import getLocalizedData from '../utils/getLocalizedData';
import PageWrap from '../components/PageLayout/PageWrap';
import SectionComponent from '../components/pageSections/PageSectionComponent';

export const query = graphql`
  query ClinicPage($id: String!) {
    sanityClinic(id: { eq: $id }) {
      name
      street
      postalCode
      key
      city {
        name
      }
      bookingQuery
      clinicHeader: _rawClinicHeader(resolveReferences: { maxDepth: 5 })
      vertical {
        title
        verticalTheme
        bookingQuery
        pageMetadata {
          titleTag {
            no
            en
          }
          metaDescription {
            no
            en
          }
          seoImage {
            alt {
              no
              en
            }
            asset {
              id
            }
          }
        }
        pageBuilder: _rawPageBuilder(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

function ClinicPage({ data: { sanityClinic }, pageContext }) {
  const { locale } = pageContext;
  const { name, street, postalCode, city, vertical, clinicHeader } =
    sanityClinic;
  const { verticalTheme = 'general', pageBuilder, pageMetadata } = vertical;

  const localizedPageBuilder = getLocalizedData(pageBuilder, locale);
  const localizedPagemetadata = getLocalizedData(pageMetadata, locale);
  const localizedHeader = clinicHeader
    ? getLocalizedData(clinicHeader, locale)
    : null;

  const pageContainsHubspot = useMemo(
    () => pageBuilder.some(({ _type }) => _type === 'hubspotForm'),
    [pageBuilder],
  );

  const bookingQuery =
    sanityClinic.bookingQuery ?? vertical.bookingQuery ?? null;

  const buildSection = (section) => {
    if (clinicHeader && section._type === 'pageHeader') {
      return (
        <SectionComponent
          key="clinicHeader"
          _key="clinicHeader"
          componentType="pageHeader"
          locale={locale}
          {...localizedHeader}
        />
      );
    }
    return (
      <SectionComponent
        key={section._key}
        componentType={section._type}
        locale={locale}
        {...section}
      />
    );
  };

  const formattedName = name.replace(/Dr\.\s?Dropin\s/, '');
  const address = `${street}, ${postalCode} ${city?.name}`;
  const description =
    `${address} | ${localizedPagemetadata?.metaDescription}`.slice(0, 160);

  return (
    <>
      {pageContainsHubspot && (
        <Helmet>
          <script
            type="text/javascript"
            src="//js.hsforms.net/forms/shell.js"
          />
        </Helmet>
      )}

      <PageWrap
        bookingQuery={bookingQuery}
        seoData={{
          ...localizedPagemetadata,
          titleTag: formattedName,
          metaTitle: `Dr. Dropin ${formattedName}`,
          metaDescription: localizedPagemetadata?.metaDescription
            ? description
            : address,
        }}
        theme={{ pageTheme: { name: verticalTheme } }}
      >
        {localizedPageBuilder.map((section) => buildSection(section))}
      </PageWrap>
    </>
  );
}
export default ClinicPage;
